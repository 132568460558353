import { Button, Card, CardContent, ComponentBaseProps, Flexbox, Form, H2, Input, Text, Theme } from '@sonika-se/ui-kit'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { createUseStyles } from 'react-jss'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const useStyles = createUseStyles<Theme, 'thanks'>(() => ({
  thanks: {
    padding: '2rem',
  }
}))

type NewsletterFormSchema = {
  email: string
}

const validationSchema = yup.object().shape<NewsletterFormSchema>({
  email: yup.string().required(),
})

export type NewsletterFormProps = ComponentBaseProps

export const NewsletterForm: React.FC<NewsletterFormProps> = (props) => {
  const { className, ...rest } = props

  const { errors, handleSubmit, register } = useForm<NewsletterFormSchema>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const [state, setState] = useState<'form' | 'post' | 'success' | 'error'>('form')

  const classes = useStyles()

  return (
    <div className={className} {...rest}>
      <Flexbox direction="column" gap="small">
        <H2 appearance="h6">Vill du vara med i loopen?</H2>
        <Text>Lämna din e-post så hör vi av oss och berättar mer vad vi kan göra för er verksamhet.</Text>
        {(state === 'form' || state === 'post') && (
          <Form
            onSubmit={handleSubmit(async (data) => {
              setState('post')

              const { result } = await addToMailchimp(data.email)

              setState(result)
            })}
            style={{ marginTop: '1rem' }}
          >
            <Flexbox gap="small">
              <Input disabled={state === 'post'} error={errors?.email?.message?.toString()} inputName="email" inputRef={register} />
              <Button appearance="danger" disabled={state === 'post'} type="submit">Heja!</Button>
            </Flexbox>
          </Form>
        )}
        {(state === 'success' || state === 'error') && (
          <Card background="gray" shadow="none">
            <CardContent className={classes.thanks}>
              {state === 'success' && (
                <Text small>
                  Tack för ditt intresse! Vi hör av oss. /Sonika ✌️
                </Text>
              )}
              {state === 'error' && (
                <Text small>
                  Något gick fel. Fyllde du i e-postadressen korrekt?{' '}
                  <Button appearance="link" onClick={() => setState('form')} size="small">Försök igen</Button>
                </Text>
              )}
            </CardContent>
          </Card>
        )}
      </Flexbox>
    </div>
  )
}

export default NewsletterForm
