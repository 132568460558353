import React from 'react'
import { Helmet } from 'react-helmet'
import { Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import Layout from '../components/Layout'
import NewsletterForm from '../components/NewsletterForm'

const useStyles = createUseStyles<Theme, 'main' | 'content' | 'form' | 'heading' | 'image' | 'text'>({
  content: {
    flex: 1,
  },
  form: {
    marginTop: '2rem !important',
    width: '50%',
    ['@media (max-width: 1200px)']: {
      width: '100%',
    },
  },
  heading: {
    fontSize: '4rem',
    maxWidth: '25ch',
    ['@media (max-width: 768px)']: {
      fontSize: '2rem',
      maxWidth: 'none',
    },
  },
  image: {
    boxShadow: '24px 16px 64px 0 rgba(0, 0, 0, 0.08)',
    maxWidth: '25vw',
    ['@media (max-width: 1600px)']: {
      display: 'none',
    },
  },
  main: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    padding: '2rem',
    ['@media (max-width: 768px)']: {
      padding: '1rem',
    },
  },
  text: {
    fontSize: '1.5rem',
    maxWidth: '60ch',
    ['@media (max-width: 768px)']: {
      fontSize: '1rem',
    },
  },
})

const Home: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content="Nästa generations plattform för personal, schemaläggning, tidrapportering." />
        <link href="/assets/icons/sonika.ico" rel="icon" type="image/x-icon" />
        <link href="/assets/icons/sonika-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/assets/icons/sonika-180x180.png" rel="icon" sizes="180x180" type="image/png" />
        <link href="/assets/icons/sonika-192x192.png" rel="icon" sizes="192x192" type="image/png" />
        <link href="/assets/icons/sonika-512x512.png" rel="icon" sizes="512x512" type="image/png" />
        <title>Sonika</title>
      </Helmet>
      <Layout>
        <div className={classes.main}>
          <Flexbox align="center" direction="row" gap="xlarge">
            <div className={classes.content}>
              <Flexbox direction="column" gap="medium">
                <Text appearance="h1" className={classes.heading}>
                  Trött på IT-system som inte hjälper verksamheten? Vi med.
                </Text>
                <Text lineHeight="onehalf" className={classes.text}>
                  Därför bygger vi Sonika – nästa generations plattform med smarta funktioner för personal, schemaläggning, tidrapportering m.m. som växer tillsammans med ditt företag.
                </Text>
                <NewsletterForm className={classes.form} />
              </Flexbox>
            </div>
          </Flexbox>
        </div>
      </Layout>
    </>
  )
}

export default Home
